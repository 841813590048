import React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import ProjectHero from "../../components/ProjectHero"
import ProjectTitle from "../../components/ProjectTitle"
import CTMGame from "../../components/CTMGame/CTMGame";

const CompareTheMarketPage = (props: PageProps) => {
    return (
        <Layout page="work" route="/portfolio/compare-the-market">
            <Seo
                image={'/compare-the-market/cover.jpg'}
                title="Compare the Market by Alistair Bancroft"
                description={"In a delightful promotion to celebrate a mouthwatering offer of 50% off pizzas through the Meerkat App, we conceptualised an engaging and interactive pizza making game, complete with special guest appearances by the beloved duo, Sergei and Oleg."}
            />
            <div className="compare-the-market-namespace">
                <ProjectHero
                    background="purple"
                    agency={'ekino / Havas Helia'}
                    body={[
                        "In a delightful promotion to celebrate a mouthwatering offer of 50% off pizzas through the Meerkat App, we conceptualised an engaging and interactive pizza making game, complete with special guest appearances by the beloved duo, Sergei and Oleg. Through the magic of gamification, captivating sounds, and interactive elements, users were invited to embark on a culinary adventure, creating their very own special pizza from a delightful selection of ingredients, guided by the adorable meerkats' own hands.", 
                        "This immersive experience not only entertained users but also instilled a sense of personalization and excitement, making the offer even more irresistible. Click the button 'Make a Pizza' below to experience the Meerkat activation full screen."
                    ]}
                    engagement={'2021'}
                    technologies={'Netlify, Javascript'}
                    position={'Front End Lead / ekino'}
                    project={{
                        label: 'COMPARE THE MARKET',
                    }}
                    title={'Project: Compare the Market'}
                    subtitle={'Meerkat App gamification: 50% off your favourite pizza'}
                />

            <section data-background="ltPurple">
                <CTMGame />
            </section>
            
            <ProjectTitle 
                background="ltPurple"
                text={{
                    title: "purple",
                    text: "black"
                }}
                content={[
                    {
                        title: 'Brand Identity Reinforcement',
                        body: ["With the Meerkats' adorable paws taking center stage, the interactive pizza making game not only brought a playful and endearing dynamic to the experience but also served as a powerful tool for reinforcing the brand identity.", "To further enhance the cheeky side of the Meerkats, we ingeniously hid playful gags throughout the app, such as playfully declaring that caviar is exclusively reserved for Oleg, adding an extra layer of amusement and charm.  These hidden surprises reinforced the brand's lighthearted personality, leaving users with a sense of joy and anticipation during their interactions with the mischievous Meerkats."]
                    },
                    {
                        title: 'Enhanced User Engagement',
                        body: ["By incorporating the Meerkats' signature playfulness with visual and audio cues, we ingeniously reinforced the brand's identity and whilst evoking positive emotions established a connection between users and the brand, making the experience more captivating and immersive."]
                    },
                    {
                        title: 'Shareability and Virality',
                        body: ["The engaging and delightful nature of the interactive experience encourages users to share their unique pizza creations and the Meerkat interaction on social media. This user-generated content became a powerful tool for brand promotion, reaching a wider audience and increasing brand awareness."]
                    }
                ]}
            />
            <div data-background="ltPurple" className="h-half-screen"></div>
            </div>
        </Layout>
    )
}

export default CompareTheMarketPage
